import {
	BN,
	BigNum,
	CandleResolution,
	MarketType,
	OrderAction,
	WrappedEvent,
} from '@drift-labs/sdk';
import {
	CANDLE_UTILS,
	Candle,
	ENUM_UTILS,
	MarketId,
	MarketKey,
	Serializer,
	UISerializableCandle,
	UISerializableOrderActionRecord,
	getPriceForUIOrderRecord,
	getSortScoreForOrderActionRecords,
	matchEnum,
	sleep,
} from '@drift/common';
import { DriftEventBuffer } from '../../hooks/driftEvents/useDriftEventBuffer';
import { fillEventFilter } from '../../hooks/driftEvents/useFillEventsBuffer';
import {
	DriftAppEventEmitter,
	DriftStore,
} from '../../stores/DriftStore/useDriftStore';
import {
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import dayjs from 'dayjs';

const DEV_LOGGING = false;
const CANDLE_SAFE_MODE = true; // Switch to tell Candle utils to handle a bug which is currently in the candles system
const SKIP_TICKS = false;
const USE_OPTIMISTIC_CANDLES_BUFFER_MS = 8000;

type MarketTradesFetcher = () => DriftStore['marketTradeHistory'];
type OraclePriceFetcher = () => BigNum;
type CandleHistoryFetcher = (
	resolution: CandleResolution,
	marketId: MarketId,
	to: number, // ms
	from?: number, // ms
	countBack?: number,
	allowedRetryAttempts?: number
) => Promise<{
	candles: UISerializableCandle[];
	lastTradeSeen: UISerializableOrderActionRecord;
	beyondMaxLookback?: boolean;
}>;
export type FirstLoadedCandles = {
	result: ReturnType<CandleHistoryFetcher> extends Promise<infer T> ? T : never;
	marketId: MarketId;
	fetchedAtTs: number;
	from: number;
	to: number;
};

class LatestCandlesCache {
	private candleMap: Map<string, UISerializableCandle>;

	constructor() {
		this.candleMap = new Map<string, UISerializableCandle>();
	}

	private getKey(
		marketIndex: number,
		marketType: MarketType,
		resolution: CandleResolution
	) {
		return `${marketIndex}_${ENUM_UTILS.toStr(marketType)}_${resolution}`;
	}

	get(
		marketIndex: number,
		marketType: MarketType,
		resolution: CandleResolution
	) {
		const key = this.getKey(marketIndex, marketType, resolution);

		return this.candleMap.get(key);
	}

	set(
		marketIndex: number,
		marketType: MarketType,
		resolution: CandleResolution,
		candle: UISerializableCandle
	) {
		const key = this.getKey(marketIndex, marketType, resolution);

		return this.candleMap.set(key, candle);
	}

	tryUpdateLatest(
		marketIndex: number,
		marketType: MarketType,
		resolution: CandleResolution,
		newCandle: UISerializableCandle
	) {
		const currentCandle = this.get(marketIndex, marketType, resolution);

		if (!currentCandle) {
			this.set(marketIndex, marketType, resolution, newCandle);
			return;
		}

		if (newCandle.start < currentCandle.start) {
			return;
		}

		this.set(marketIndex, marketType, resolution, newCandle);
	}
}

/**
 * This client is used to keep candles in sync with the market. The logic is roughly as follows:
 *
 * Initial Candle Construction:
 * - Fetch candles from the history server
 * - The history server responds with the candles, and also with the most recent trade that has been incorporated into those candles. This is useful to detect any gaps where some trades on the market may not have been converted into candles yet.
 * - The client uses the most recent trade in the candles response, to find any missing trades that will be in the local state - which would be loaded separately but available via the currentMarketTradesFetcher.
 * - The client turns these missing trades into candles, and merges them into the candles that were returned by the history server.
 *
 * Live Updates:
 * - The client constantly stores the most recent trade that has been incorporated into the candles and returned via the fetchCandles method.
 * - One each "tick", the client constructs new candle data by comparing the most recent trade in the candles to the most recent trade in the market. Then returns these new candles.
 */
export class UICandleClient {
	/**
	 * We use the last seen candle so that we know what previous candle we need to stitch new trades onto.
	 */
	private lastSeenCandlesCache = new LatestCandlesCache();

	/**
	 * We use the last seen trade as an "index" to determine which trades have already been integrated, or need to be integrated, with the current candles in each tick.
	 *
	 * Keyed by a marketId.key
	 */
	private lastTradeSeenTrade: Map<MarketKey, UISerializableOrderActionRecord> =
		new Map();

	/**
	 * We use the "for tick" params as the indexes for each tick of candle updates. We store the tick ones seperates from the other ones to protect against race-conditions and updates that can come mid-tick
	 *
	 * Keyed by a marketId.key
	 */
	private lastTradeSeenTradeForTick: Map<
		MarketKey,
		UISerializableOrderActionRecord
	> = new Map();

	/**
	 * Keyed by a tradingview subscriberGuid ("<marketName_#_<resolution>"
	 */
	private lastSeenCandleForTick: Record<string, UISerializableCandle> = {};

	/**
	 * A map of tradingview "subscriberGuid"s that map to setInterval subscriptions
	 *
	 * The TradingView wodget can subscribe and unsubscribe to to this, but it does not necessarily happen synchronously, so we want to keep these as a map that can have a few active at a time. Use methods 'clearSubscription' and 'clearAllSubscriptions' to unsubscribe.
	 */
	private currentSubscriptions: Record<string, ReturnType<typeof setTimeout>> =
		{};

	/**
	 * This static member is used to access optimistically fetched candles.
	 * A buffer period `USE_OPTIMISTIC_CANDLES_BUFFER_MS` is used to determine whether to use these optimistically fetched candles.
	 */
	static firstLoadedCandles?: FirstLoadedCandles;

	static hasLoadedFirstLoadedCandlesResolver: (() => void) | null = null;
	static hasLoadedFirstLoadedCandles = new Promise<void>((resolve) => {
		UICandleClient.hasLoadedFirstLoadedCandlesResolver = resolve;
	});

	constructor(
		private getRecentMarketTrades: MarketTradesFetcher,
		private unhandledMarketTradesBuffer: DriftEventBuffer<
			WrappedEvent<'OrderActionRecord'>
		>,
		private getOraclePrice: OraclePriceFetcher,
		private getCandleHistory: CandleHistoryFetcher,
		private appEventEmitter: DriftAppEventEmitter
	) {}

	public static setFirstLoadedCandles = (
		firstLoadedCandles: FirstLoadedCandles
	) => {
		UICandleClient.firstLoadedCandles = firstLoadedCandles;
	};

	public setUnhandledMarketTradesBuffer = (
		newBuffer: DriftEventBuffer<WrappedEvent<'OrderActionRecord'>>
	) => {
		this.unhandledMarketTradesBuffer = newBuffer;
	};

	private updateLastSeenCandleFromNewCandles(
		marketIndex: number,
		marketType: MarketType,
		resolution: CandleResolution,
		newCandles: UISerializableCandle[]
	) {
		// For some reason the trading view chart sometimes requests candles in the future which messes with how this component tracks "new trades" to update candles with, so guard against this.
		const filteredCandles = newCandles.filter(
			(candle) => candle.start.toNumber() <= Date.now()
		);

		if (filteredCandles.length === 0) return;

		const newestFilteredCandle = filteredCandles.sort(
			(a, b) => b.start.toNumber() - a.start.toNumber()
		)[0];

		this.lastSeenCandlesCache.tryUpdateLatest(
			marketIndex,
			marketType,
			resolution,
			newestFilteredCandle
		);
	}

	private awaitRecentTradeHistoryLoaded = async (marketKey: MarketKey) => {
		const tradesLoaded = () => {
			const recentTrades = this.getRecentMarketTrades()?.trades;
			return recentTrades && recentTrades.length > 0;
		};

		// Golden path:
		if (tradesLoaded()) {
			return;
		}

		let tradesLoadedPromiseResolver: () => void;

		// eslint-disable-next-line no-async-promise-executor
		const tradesLoadedPromise = new Promise<void>(async (resolve) => {
			tradesLoadedPromiseResolver = resolve;

			// # Backup plan to loop and wait for trades to load
			let counter = 0;
			while (!tradesLoaded() && counter < 3) {
				await sleep(500);
				counter++;
			}
		});

		// # Set up trades loaded event listener
		const tradesLoadedEventHandler = (incomingMarketKey: MarketKey) => {
			if (marketKey !== incomingMarketKey) return;

			if (tradesLoaded()) {
				tradesLoadedPromiseResolver();
			}
		};

		this.appEventEmitter.on('recentTradesLoaded', tradesLoadedEventHandler);

		await tradesLoadedPromise;

		this.appEventEmitter.off('recentTradesLoaded', tradesLoadedEventHandler);

		return;
	};

	/**
	 * Bandaid for edge cases where recent trade and candles don't match
	 * @param candles
	 * @param mostRecentTrade
	 */
	private bandaidLatestCandles = (
		candles: UISerializableCandle[],
		mostRecentTrade: UISerializableOrderActionRecord
	) => {
		if (!candles || candles.length === 0 || !mostRecentTrade) return;

		const mostRecentCandle = candles[candles.length - 1];
		const mostRecentCandlePrice = mostRecentCandle.fillClose;

		const mostRecentTradePrice = getPriceForUIOrderRecord(mostRecentTrade);

		if (!mostRecentTradePrice.eq(mostRecentCandlePrice)) {
			console.error(`Caught edge-case where candle bandaid was required`, {
				mostRecentCandle,
				mostRecentTrade,
				recentTradeState: this.getRecentMarketTrades(),
			});

			mostRecentCandle.fillClose = BigNum.from(
				mostRecentTradePrice.val,
				mostRecentTradePrice.precision
			);
		}
	};

	public fetchCandles = async (
		resolution: CandleResolution,
		marketId: MarketId,
		to: number, // ms
		from?: number, // ms
		countBack?: number,
		allowedRetryAttempts = 1
	): Promise<{
		candles: UISerializableCandle[];
		noData?: boolean;
	}> => {
		const fetchIsForLatestCandles = to >= Date.now();

		await UICandleClient.hasLoadedFirstLoadedCandles;

		// Wait for recent trade history to be loaded
		// await this.awaitRecentTradeHistoryLoaded(marketId.key);

		const isUseOptimisticallyFetchedCandles = UICandleClient.firstLoadedCandles
			? UICandleClient.firstLoadedCandles.fetchedAtTs >
					Date.now() - USE_OPTIMISTIC_CANDLES_BUFFER_MS &&
			  marketId.equals(UICandleClient.firstLoadedCandles.marketId) &&
			  resolution ===
					UICandleClient.firstLoadedCandles.result.candles[0].resolution &&
			  dayjs(UICandleClient.firstLoadedCandles.from)
					.set('second', 0)
					.isSameOrBefore(dayjs(from).set('second', 0)) &&
			  dayjs(UICandleClient.firstLoadedCandles.to)
					.set('second', 0)
					.isSameOrAfter(dayjs(to).set('second', 0))
			: false;

		let historicalData: ReturnType<CandleHistoryFetcher> extends Promise<
			infer T
		>
			? T
			: never;

		if (isUseOptimisticallyFetchedCandles) {
			historicalData = UICandleClient.firstLoadedCandles.result;
		} else {
			historicalData = await this.getCandleHistory(
				resolution,
				marketId,
				to,
				from,
				countBack,
				allowedRetryAttempts
			);
		}

		const EMPTY_RESULT = {
			candles: [] as any[],
			noData: true,
		};

		if (!historicalData) {
			return EMPTY_RESULT;
		}

		const beyondMaxLookback = historicalData.beyondMaxLookback;

		const candles = historicalData.candles;

		// Check for trades in local history which are newer than ones returned by history server
		//// Get last seen trade in candles in history server

		// NOTE -- sometimes for higher resolution candles, the lastTradeSeen here is indeed the most recent but not included in the candle. Todo: figure this out in history server
		const lastSeenTradeInCandles = historicalData.lastTradeSeen;

		//// Get trades in local history which are newer than ^ and between the from/to params
		const newTradesMissingFromCandles = this.fetchNewerTradesThanTarget(
			lastSeenTradeInCandles
		).filter((trade) => trade.ts.toNumber() * 1000 < to);

		//// Construct candles for the new trades
		const newMissingCandles = CANDLE_UTILS.mergeTradesIntoCandles(
			newTradesMissingFromCandles,
			resolution
		);

		//// Stitch these candles to the candles from the DB
		const stichedCandles = CANDLE_UTILS.stitchCandles([
			...candles.map((candle) => Candle.fromUICandle(candle, CANDLE_SAFE_MODE)),
			...newMissingCandles,
		]);

		const stitchedUICandles = stichedCandles.map((candle) =>
			candle.toUISerializable()
		);

		// Update last seen candle
		this.updateLastSeenCandleFromNewCandles(
			marketId.marketIndex,
			marketId.marketType,
			resolution,
			stitchedUICandles
		);

		// Set last seen trade
		if (DEV_LOGGING) {
			console.log(
				`Last trade in history server candles : ${new Date(
					historicalData.lastTradeSeen.ts.toNumber() * 1000
				).toLocaleString()}`
			);

			if (newTradesMissingFromCandles.length > 0) {
				console.log(
					`Found trades in history missing from candles in initial fetch:`
				);

				console.log(
					newTradesMissingFromCandles.map((trade) =>
						new Date(trade.ts.toNumber() * 1000).toLocaleString()
					)
				);
			}
		}

		if (newTradesMissingFromCandles.length > 0) {
			const descNewerTrades = [...newTradesMissingFromCandles]
				.sort(getSortScoreForOrderActionRecords)
				.reverse();
			const newestNewerTrade = descNewerTrades[0];
			this.lastTradeSeenTrade.set(marketId.key, newestNewerTrade);
		} else {
			this.lastTradeSeenTrade.set(marketId.key, lastSeenTradeInCandles);
		}

		if (fetchIsForLatestCandles) {
			this.bandaidLatestCandles(
				stitchedUICandles,
				this.lastTradeSeenTrade.get(marketId.key)
			);
		}

		// Return candles
		return {
			candles: stitchedUICandles,
			noData: beyondMaxLookback,
		};
	};

	private warnNoLastSeenTrade = () => {
		console.warn(`No last seen trade during candle subscription loop`);
	};

	private warnMarketIndexMismatch = () => {
		console.warn(
			`Asked to fetch new candles for non-matching markets .. this shouldn't happen repeatedly`
		);
	};

	private fetchNewerTradesThanTarget = (
		targetTrade: UISerializableOrderActionRecord
	) => {
		if (!targetTrade) return [];

		const currentTradeHistory = this.getRecentMarketTrades();
		const tradesForCandles = currentTradeHistory.trades.filter(
			CANDLE_UTILS.filterOrderActionsForCandles
		);
		const newerTrades = tradesForCandles
			.filter(
				(tradeRecord) =>
					tradeRecord.marketIndex === targetTrade.marketIndex &&
					ENUM_UTILS.match(targetTrade.marketType, tradeRecord.marketType)
			)
			.filter(
				(tradeRecord) =>
					getSortScoreForOrderActionRecords(tradeRecord, targetTrade) === 1
			)
			.filter((trade) => ENUM_UTILS.match(trade.action, OrderAction.FILL));

		return newerTrades;
	};

	/**
	 * WARNING FOR SOMEONE IN THE FUTURE: If you ever something weird here like new trades not turning into candles when you expect them to (and you're running against a local environment) => Your local DB might have a mix of devnet and mainnet candles/trades .. the  slots in these are very different for the same timestamps, which screws with the sorting of the trades to turn into candles
	 * @param marketId
	 * @returns
	 */
	private fetchNewTradesForTick = async (
		marketId: MarketId
	): Promise<UISerializableOrderActionRecord[]> => {
		const lastSeenTradeRecord = this.lastTradeSeenTradeForTick.get(
			marketId.key
		);

		if (!lastSeenTradeRecord) {
			this.warnNoLastSeenTrade();
			return [];
		}

		const filteredUnhandledFillEvents = (
			(await this.unhandledMarketTradesBuffer.releaseAndGet()) ?? []
		).filter((event) => fillEventFilter(event, { marketId: marketId }));

		if (
			!filteredUnhandledFillEvents ||
			filteredUnhandledFillEvents.length === 0
		) {
			return [];
		}

		const unhandledTrades = filteredUnhandledFillEvents
			.map(Serializer.Serialize.OrderActionRecord)
			.map(Serializer.Deserialize.UIOrderActionRecord);

		const lastSeenTradeMarket = new MarketId(
			lastSeenTradeRecord?.marketIndex,
			lastSeenTradeRecord?.marketType
		);

		const firstUnhandledTrade = unhandledTrades[0];
		const unhandledTradesmarket = new MarketId(
			firstUnhandledTrade.marketIndex,
			firstUnhandledTrade.marketType
		);

		if (!lastSeenTradeMarket.equals(unhandledTradesmarket)) {
			this.warnMarketIndexMismatch();
			return [];
		}

		const newTrades = unhandledTrades.filter(
			(tradeRecord) =>
				getSortScoreForOrderActionRecords(tradeRecord, lastSeenTradeRecord) ===
				1
		);

		if (DEV_LOGGING) {
			const oldestTradeInHistory = [...unhandledTrades].sort(
				(tradeA, tradeB) => tradeA.slot - tradeB.slot
			)[0];

			if (oldestTradeInHistory) {
				console.log(
					`Oldest trade in local market history : ${new Date(
						oldestTradeInHistory.ts.toNumber() * 1000
					)}`
				);
			}
			console.log(`New Trades for tick:`);
			console.log(
				newTrades.map((trade) => new Date(trade.ts.toNumber() * 1000))
			);
		}

		const filteredAndSortedTrades = newTrades
			.filter(
				(trade) =>
					trade.marketIndex === marketId.marketIndex &&
					ENUM_UTILS.match(trade.marketType, marketId.marketType)
			)
			.sort(getSortScoreForOrderActionRecords);

		const newLatestTrade =
			filteredAndSortedTrades[filteredAndSortedTrades.length - 1];

		if (newLatestTrade) {
			// Update last seen trade
			this.lastTradeSeenTrade.set(marketId.key, newLatestTrade);
		}

		return filteredAndSortedTrades;
	};

	private getTvSubscriberGuid = (
		isPerp: boolean,
		marketIndex: number,
		resolution: CandleResolution
	) => {
		const marketName = isPerp
			? PERP_MARKETS_LOOKUP[marketIndex].symbol
			: SPOT_MARKETS_LOOKUP[marketIndex].symbol;
		return `${marketName}_#_${resolution}`;
	};

	/**
	 * Returns a new candle from new trades merged with previous candle if in same timeslot and resolution
	 * @param resolution
	 * @param marketIndex
	 * @returns
	 */
	private getNewCandleForTick = async (
		resolution: CandleResolution,
		marketId: MarketId
	): Promise<UISerializableCandle> => {
		const subscriberGuid = this.getTvSubscriberGuid(
			marketId.isPerp,
			marketId.marketIndex,
			resolution
		);

		const lastSeenCandle = this.lastSeenCandleForTick[subscriberGuid];

		const previousCandle = lastSeenCandle
			? Candle.fromUICandle(lastSeenCandle)
			: undefined;

		const newTrades = await this.fetchNewTradesForTick(marketId);

		const now = Math.max(
			new Date().getTime(),
			lastSeenCandle?.start?.toNumber() ?? 0
		);

		const newCandleStartTime = CANDLE_UTILS.startTimeForCandle(now, resolution);

		let newCandle: UISerializableCandle;

		const lastSeenTradeInMarket = this.lastTradeSeenTradeForTick.get(
			marketId.key
		);

		// sometimes the latest seen trade is not in the candle despite no newTrades being fetched
		// possibly due to a race condition that i'm having trouble pinning down atm
		// either way, make sure that trade gets appended to the candle if it's not already
		if (
			newTrades.length === 0 &&
			previousCandle &&
			lastSeenTradeInMarket &&
			!lastSeenTradeInMarket.oraclePrice.eq(lastSeenCandle.oracleClose)
		) {
			newTrades.push(lastSeenTradeInMarket);
		}

		if (newTrades.length === 0 && previousCandle) {
			// blank new candle stemming from previous
			const newBaseCandle = Candle.fromBlankAfterPrevious(
				previousCandle,
				resolution,
				newCandleStartTime
			);

			newCandle = newBaseCandle.toUISerializable();
		} else if (newTrades.length === 0 && !previousCandle) {
			// No previous candle and no trades .. so create a blank one using oracle price
			const currentOraclePrice = this.getOraclePrice();
			const lastOraclePrice =
				lastSeenTradeInMarket?.oraclePrice?.val ?? currentOraclePrice.val;

			const newBaseCandle = Candle.blank(
				new BN(newCandleStartTime),
				resolution,
				lastOraclePrice,
				lastOraclePrice
			);

			newCandle = newBaseCandle.toUISerializable();
		} else {
			// Regular case, merging new trades into previous candle
			const candlesFromNewTrades = newTrades.map((trade) =>
				CANDLE_UTILS.candleFromTrade(newCandleStartTime, resolution, trade)
			);

			const mergedCandleFromNewTrades =
				Candle.fromMergeAll(candlesFromNewTrades);

			const mergedNewCandle = Candle.fromMerge(
				mergedCandleFromNewTrades,
				previousCandle
			);

			newCandle = mergedNewCandle.toUISerializable();
		}

		// Update last seen candle
		this.lastSeenCandlesCache.tryUpdateLatest(
			marketId.marketIndex,
			marketId.marketType,
			resolution,
			newCandle
		);

		return newCandle;
	};

	private stateIsPreparedForSubscriptionTick = (marketId: MarketId) => {
		const lastSeenTradeRecord = this.lastTradeSeenTrade.get(marketId.key);

		if (!lastSeenTradeRecord) {
			const recentTrades = this.getRecentMarketTrades();
			return (
				recentTrades?.trades?.length > 0 &&
				marketId.equals(
					new MarketId(
						recentTrades.trades[0].marketIndex,
						recentTrades.trades[0].marketType
					)
				)
			);
		}

		const lastSeenTradeMarket = new MarketId(
			lastSeenTradeRecord.marketIndex,
			lastSeenTradeRecord.marketType
		);

		if (!lastSeenTradeMarket.equals(marketId)) {
			this.warnMarketIndexMismatch();
			return false;
		}

		const currentTradeHistory = this.getRecentMarketTrades();
		const currentTradeHistoryMarketId = currentTradeHistory.market.marketId;

		if (!currentTradeHistoryMarketId.equals(marketId)) {
			this.warnMarketIndexMismatch();
			return false;
		}

		return true;
	};

	/**
	 * This sets what the "last seen" candle and trade is for the tick that is about to run
	 * @param resolution
	 */
	private setLastSeenDataForTick = (
		marketIndex: number,
		marketType: MarketType,
		resolution: CandleResolution
	) => {
		const subscriberGuid = this.getTvSubscriberGuid(
			matchEnum(marketType, MarketType.PERP),
			marketIndex,
			resolution
		);
		this.lastSeenCandleForTick[subscriberGuid] = this.lastSeenCandlesCache.get(
			marketIndex,
			marketType,
			resolution
		);

		const marketId = new MarketId(marketIndex, marketType);

		this.lastTradeSeenTradeForTick.set(
			marketId.key,
			this.lastTradeSeenTrade.get(marketId.key)
		);
	};

	/**
	 * calls the onTick callback with the new state of the current candle every tick, based on provided resolution and market
	 * @param resolution
	 * @param marketIndex
	 * @param tickRate
	 * @param onTick
	 */
	public subscribeToCurrentCandle = (
		subscriberGuid: string,
		resolution: CandleResolution,
		marketId: MarketId,
		tickRate: number, // ms
		onTick: (
			newCandleForTimeslot: UISerializableCandle,
			needToRefresh: boolean
		) => void
	) => {
		// Clear existing subscription for same subscriber guid...
		// Do we maybe want this to be based on the market id only? Need to do some testing to see.
		// if (this.currentSubscriptions[subscriberGuid]) {
		// 	this.clearSubscription(subscriberGuid);
		// }
		this.clearAllSubscriptions(); // clear all subscriptions so that previous subscriptions will not be throwing warnings at `stateIsPreparedForSubscriptionTick`

		this.currentSubscriptions[subscriberGuid] = setInterval(async () => {
			if (!this.stateIsPreparedForSubscriptionTick(marketId)) {
				// Skipping this tick because state isn't ready
				return;
			}

			this.setLastSeenDataForTick(
				marketId.marketIndex,
				marketId.marketType,
				resolution
			);

			const newCandle = await this.getNewCandleForTick(resolution, marketId);

			if (SKIP_TICKS) {
				return;
			}

			onTick(newCandle, false);
		}, tickRate);
	};

	public clearSubscription = (subscriberGuid: string) => {
		if (this.currentSubscriptions[subscriberGuid]) {
			clearInterval(this.currentSubscriptions[subscriberGuid]);
			this.currentSubscriptions[subscriberGuid] = undefined;
		}
	};

	public clearAllSubscriptions = () => {
		Object.keys(this.currentSubscriptions).forEach((key) => {
			clearInterval(this.currentSubscriptions[key]);
			this.currentSubscriptions[key] = undefined;
		});
	};

	public createSubscriptionGuid = (
		marketSymbol: string,
		resolution: CandleResolution
	) => {
		return `${marketSymbol}_#_${resolution}`;
	};
}
