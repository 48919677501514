import {
	EventSubscriberType,
	GlobalEventSubscriberType,
} from 'src/environmentVariables/EnvironmentVariableTypes';
import { create } from 'zustand';
import { produce, Draft } from 'immer';
import Env from '../environmentVariables/EnvironmentVariables';
import { syncGetCurrentSettings } from '../environmentVariables/EnvironmentVariables';
import useFeatureFlagStore from './useFeatureFlagStore';
import { dlog } from '../dev';

// # See README.md in src/hooks/driftEvents for more information on the subscription state

export type SubscriptionState<T> = {
	subscriptionType: T;
};

export interface SubscriptionStateStore {
	set: (fn: (state: Draft<SubscriptionStateStore>) => void) => void;
	get: () => SubscriptionStateStore;

	// Subscription states
	selfEventsState: SubscriptionState<EventSubscriberType>;
	globalEventsState: SubscriptionState<GlobalEventSubscriberType>;

	// Setters
	updateSelfEventSubscriberType: (type: EventSubscriberType) => void;
	updateGlobalEventSubscriberType: (type: GlobalEventSubscriberType) => void;
	handleGlobalEventsFallback: () => void;
	handleGlobalEventsFallbackUp: () => void;
}

const GLOBAL_EVENTS_FALLBACK_STATE = {
	lastFallbackTime: 0,
};

const GLOBAL_EVENTS_FALLUP_COOLDOWN_STATE = {
	count: 0,
	lastFallupTime: 0,
};

const FALL_UP_GRACE_PERIOD_MS = 1000 * 60;

const getCurrentCooldownTime = () => {
	return 1000 * 60 * 2 ** GLOBAL_EVENTS_FALLUP_COOLDOWN_STATE.count;
};

const checkFallupCooldownState = () => {
	// If the most recent fallback was too recent, don't fall up
	if (
		GLOBAL_EVENTS_FALLBACK_STATE.lastFallbackTime >
		Date.now() - FALL_UP_GRACE_PERIOD_MS
	) {
		return false;
	}

	// Use FALLUP_COUNT to determine an exponential backoff on falling up in case we keep failing repeatedly
	if (GLOBAL_EVENTS_FALLUP_COOLDOWN_STATE.count > 0) {
		const timeSinceLastFallback =
			Date.now() - GLOBAL_EVENTS_FALLUP_COOLDOWN_STATE.lastFallupTime;

		const cooldownTime = getCurrentCooldownTime();

		if (timeSinceLastFallback < cooldownTime) {
			return false;
		}
	}

	return true;
};

const handleFallupCooldownIncrement = () => {
	GLOBAL_EVENTS_FALLUP_COOLDOWN_STATE.count++;
	GLOBAL_EVENTS_FALLUP_COOLDOWN_STATE.lastFallupTime = Date.now();
};

const handleFallbackIncrement = () => {
	GLOBAL_EVENTS_FALLBACK_STATE.lastFallbackTime = Date.now();
};

const getInitialSelfEventsState =
	(): SubscriptionState<EventSubscriberType> => {
		const preferredEventSubscriberType =
			syncGetCurrentSettings()?.eventSubscriberType;

		return {
			subscriptionType: preferredEventSubscriberType ?? 'websocket',
		};
	};

const getInitialGlobalEventsState =
	(): SubscriptionState<GlobalEventSubscriberType> => {
		const preferredGlobalEventSubscriberType =
			syncGetCurrentSettings()?.eventSubscriberType;

		const tradePublisherEnabled = Env.enableDlobWebsocketTradesChannel;

		return {
			subscriptionType: tradePublisherEnabled
				? 'trade-publisher'
				: preferredGlobalEventSubscriberType ?? 'websocket',
		};
	};

const useSubscriptionStateStore = create<SubscriptionStateStore>(
	(set, get): SubscriptionStateStore => {
		const setState = (fn: (state: Draft<SubscriptionStateStore>) => void) =>
			set(produce(fn));

		return {
			set: setState,
			get: () => get(),

			// Initial states
			selfEventsState: getInitialSelfEventsState(),
			globalEventsState: getInitialGlobalEventsState(),

			// Setters
			updateSelfEventSubscriberType: (type: EventSubscriberType) => {
				setState((state) => {
					state.selfEventsState.subscriptionType = type;
				});
			},
			updateGlobalEventSubscriberType: (type: GlobalEventSubscriberType) => {
				setState((state) => {
					state.globalEventsState.subscriptionType = type;
				});
			},
			handleGlobalEventsFallback: () => {
				handleFallbackIncrement();

				const currentSubscriptionType =
					get().globalEventsState.subscriptionType;

				const eventsServerIsEnabled =
					useFeatureFlagStore
						.getState()
						.flagEnabled('PREFER_EVENTS_SERVER_EVENTS') ||
					syncGetCurrentSettings()?.eventSubscriberType === 'events-server';

				const newSubscriptionType: GlobalEventSubscriberType =
					currentSubscriptionType === 'trade-publisher' && eventsServerIsEnabled
						? 'events-server'
						: currentSubscriptionType === 'trade-publisher'
						? 'websocket'
						: currentSubscriptionType === 'events-server'
						? 'websocket'
						: 'polling';

				setState((state) => {
					state.globalEventsState.subscriptionType = newSubscriptionType;
				});
			},
			handleGlobalEventsFallbackUp: () => {
				const fallupAllowed = checkFallupCooldownState();

				if (!fallupAllowed) {
					dlog(
						`optimised_event_subscriptions`,
						`skipping_fall_up_attempt_because_cooldown_not_passed (current cooldown time: ${
							getCurrentCooldownTime() / 1000 / 60
						}mins)`
					);
					return;
				}

				const currentSubscriptionType =
					get().globalEventsState.subscriptionType;

				const tradePublisherIsEnabled = Env.enableDlobWebsocketTradesChannel;
				const eventsServerIsEnabled =
					useFeatureFlagStore
						.getState()
						.flagEnabled('PREFER_EVENTS_SERVER_EVENTS') ||
					syncGetCurrentSettings()?.eventSubscriberType === 'events-server';

				const peakSubscriptionOption: GlobalEventSubscriberType =
					tradePublisherIsEnabled
						? 'trade-publisher'
						: eventsServerIsEnabled
						? 'events-server'
						: 'websocket';

				const fallUpNotNecessary =
					currentSubscriptionType === peakSubscriptionOption;

				if (fallUpNotNecessary) {
					dlog(
						`optimised_event_subscriptions`,
						`skipping_fall_up_attempt_because_fall_up_not_necessary`
					);
					return;
				}

				handleFallupCooldownIncrement();

				dlog(
					`optimised_event_subscriptions`,
					`falling_up_global_events_subscription_to_${peakSubscriptionOption}`
				);

				setState((state) => {
					state.globalEventsState.subscriptionType = peakSubscriptionOption;
				});
			},
		};
	}
);

export default useSubscriptionStateStore;
