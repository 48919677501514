'use client';

import {
	EventMap,
	OrderAction,
	OrderActionRecord,
	WrappedEvent,
} from '@drift-labs/sdk';
import React, {
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Subject } from 'rxjs';
import { DriftBlockchainEventSubscriberContext } from '../driftBlockchainEventSubscriberProvider';
import { Commitment } from '@solana/web3.js';
import { dedupeSubject } from '../../utils/rxjs/DedupedSubject';
import { Observable } from 'react-use/lib/useObservable';
import { ENUM_UTILS } from '@drift/common';

// This flag allows extra robustness by providing redundancy by forwarding global action events to the strict current-user events subject, and vice-versa. It requires deduplication because otherwise in the case where the data sources are healthy there will be duplicate events.
export const MERGE_USER_AND_GLOBAL_EVENT_SUBJECTS = true;
const DEDUPE_WINDOW_SIZE = 50;

/**
 * This provider is responsible for creating the subjects that the drift events can be piped into.
 */

type DriftBlockchainEventSubjectContextType = {
	userEventSubject: Subject<WrappedEvent<keyof EventMap>>;
	userEventObservable: Observable<WrappedEvent<keyof EventMap>>;
	globalFillEventSubject: Subject<WrappedEvent<'OrderActionRecord'>>;
	globalFillEventObservable: Observable<WrappedEvent<'OrderActionRecord'>>;
	userEventSubjectCommitment: Commitment;
	globalFillEventSubjectCommitment: Commitment;
};

const INITIAL_CONTEXT_VALUE: DriftBlockchainEventSubjectContextType = {
	userEventSubject: undefined,
	userEventObservable: undefined,
	globalFillEventSubject: undefined,
	globalFillEventObservable: undefined,
	userEventSubjectCommitment: undefined,
	globalFillEventSubjectCommitment: undefined,
};

/**
 * userEventSubscriber: EventSubscriber for the current user's events
 * globalFillEventSubscriber: EventSubscriber for market-wide order action events
 */
export const DriftBlockchainEventSubjectContext =
	React.createContext<DriftBlockchainEventSubjectContextType>(
		INITIAL_CONTEXT_VALUE
	);

export const DriftBlockchainEventSubjectProvider = (
	props: PropsWithChildren<any>
) => {
	const eventSubscriberContext = useContext(
		DriftBlockchainEventSubscriberContext
	);
	const [contextValue, setContextValue] =
		useState<DriftBlockchainEventSubjectContextType>(INITIAL_CONTEXT_VALUE);

	useEffect(() => {
		const userEventSubject = new Subject<WrappedEvent<keyof EventMap>>();

		let userEventObservable = userEventSubject.asObservable();

		const globalFillEventSubject = new Subject<
			WrappedEvent<'OrderActionRecord'>
		>();

		let globalFillEventObservable = globalFillEventSubject.asObservable();

		// Apply the sliding window deduplication to the user event subject if we're merging the user events with the global order action events
		if (MERGE_USER_AND_GLOBAL_EVENT_SUBJECTS) {
			userEventObservable = dedupeSubject(
				userEventSubject,
				DEDUPE_WINDOW_SIZE,
				(event) => {
					const isFillEvent =
						event.eventType === 'OrderActionRecord' &&
						ENUM_UTILS.match(
							(event as OrderActionRecord).action,
							OrderAction.FILL
						);

					return `${event.eventType}::${event.txSig}::${
						isFillEvent
							? (event as OrderActionRecord).fillRecordId.toString()
							: event.txSigIndex
					}`;
				}
			);

			globalFillEventObservable = dedupeSubject(
				globalFillEventSubject,
				DEDUPE_WINDOW_SIZE,
				(event) => {
					return `${event.eventType}::${
						event.txSig
					}::${event.fillRecordId.toString()}::${ENUM_UTILS.toStr(
						event.actionExplanation
					)}`;
				}
			);
		}

		userEventObservable.subscribe();
		globalFillEventSubject.subscribe();

		setContextValue({
			userEventSubject,
			userEventObservable,
			globalFillEventSubject: globalFillEventSubject,
			globalFillEventObservable: globalFillEventObservable,
			userEventSubjectCommitment: eventSubscriberContext.commitment,
			globalFillEventSubjectCommitment: eventSubscriberContext.commitment,
		});

		return () => {
			userEventSubject.unsubscribe();
			globalFillEventSubject.unsubscribe();
		};
	}, [eventSubscriberContext]);

	return (
		<DriftBlockchainEventSubjectContext value={contextValue}>
			{props.children}
		</DriftBlockchainEventSubjectContext>
	);
};
